/**
Style edit pages
**/


#edit-account,
#user-profile-form {
  #edit-actions--2,
  #edit-timezone {
    clear: both;
  }
  #edit-field-user-picture {
    display: flex;
    margin-bottom: 1em;
    .image-preview {
      width: 50%;
    }
    .image-widget-data {
      width: 49%;
    }
  }
}

#user-profile-form {
  #edit-field-user-picture {
    .form-item-field-user-picture-und-0-alt,
    .manualcrop-style-button-holder {
      display: none;
    }
  }
}
