.featured_article_wrapper {
  background-position: center center;
  background-size: cover;
  display: inline-block;
  position: relative;
  width: 100%;

  .featured_article_body {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    float: right;
    justify-content: center;
    left: 0;
    min-height: 500px;
    padding: 60px 35px 40px 45px;
    width: 400px;

    .featured_article_inner {
      max-width: 800px;
      padding: 50px 0;

      h4 {
        color: $white;
        font-size: calc(22px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
        margin: 0;
      }

      .post_date {
        color: $raw-sienna;
        font-style: italic;
        margin: 5px 0 0;
      }

      p {
        font-family: RalewayMedium;
        font-size: 14px;
        margin: 15px 0 0;

        a {
          color: $white;
          font-family: RalewaySemibold;
          letter-spacing: 5px;
          transition: 0.5s;

          &:hover {
            color: $wasabi;
          }
        }
      }
    }

    &:hover {

      p {

        a {
          color: $wasabi;
        }
      }
    }
  }

  @include md {

    .featured_article_body {
      width: 100%;
    }
  }
}

.featured_list_wrapper {
  display: table;
  margin: 0;

  .featured_list_image {
    display: table-cell;
    vertical-align: middle;
    width: 100px;

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
    }
  }

  .featured_list_body {
    display: table-cell;
    font-family: RalewayBold;
    font-size: 14px;
    line-height: 18px;
    padding: 0 0 0 15px;
    vertical-align: top;

    .post_date {
      color: $raw-sienna;
      font-style: italic;
      margin: 5px 0 0;
    }

    a {
      color: $white;
      transition: 0.5s;

      h3 {
        font-size: 14px;
        margin: 0;
      }

      &:hover {
        color: $wasabi;
      }
    }
  }
}

.front {

  .feature-wrapper {
    background: $primary-color;
    display: inline-block;
    float: left;
    padding: 0;
    width: 100%;

    .recent-feature-left {
      float: left;
      // width: calc((800px) + ((100% - 1200px) / (2)));
      width: 100%;

      > div {
        padding: 0;
      }

      .featured_article_body {

        .featured_article_inner {
          margin-left: auto;
          max-width: 800px;
          padding: 0;
        }
      }

      .hidden-links {
        display: none;
      }
    }

    .recent-feature-right {
      float: left;
      padding: 35px 0;
      // width: calc((400px) + ((100% - 1200px) / (2)));
      width: 100%;

      > div {
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 50px;
        width: 100%;
      }

      .pane-title {
        color: $white;
      }

      .view-latest-news {

        > div {
          display: grid;
          grid-column-gap: 70px;
          grid-row-gap: 35px;
          grid-template-columns: 1fr 1fr 1fr;
        }

        .view-footer {
          display: block;
          margin-top: 30px;
          text-align: center;

          p {
            margin: 0;
          }

          a {
            letter-spacing: 5px;
            color: $gray;
            font-family: RalewayBold;
            text-transform: uppercase;
            transition: 0.5s;

            &:hover {
              color: $wasabi;
            }
          }
        }
      }
    }
  }

  @include xxl {

    .feature-wrapper {

      .recent-feature-right {

        .panels-flexible-region-inside {
          padding: 0 15px;
        }
      }
    }
  }

  @include lg {

    .feature-wrapper {

      .recent-feature-right {

        .view-latest-news {

          > div {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }

  @include md {

    .feature-wrapper {

      .recent-feature-right {
        max-width: 100%;
        padding: 15px;
        width: 100%;

        .view-latest-news {

          > div {
            grid-template-columns: 1fr;
          }
        }
        
        .panels-flexible-region-inside {
          margin: 0 auto;
          max-width: 100%;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
