#mini-panel-email_sign_up_popup,
#mini-panel-donate_popup {
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;

  > .panel-col {
    max-width: 500px;
    position: relative;
    width: 100%;

    .logo {
      position: absolute;
      right: 15px;
      top: 15px;

      img {
        aspect-ratio: auto !important;
        width: 150px !important;
      }
    }

    .pane-bundle-quick-links {
      left: 15px;
      position: absolute;
      top: 15px;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          margin: 0;

          a {
            background: rgba(31, 56, 30, 0.8);
            border: 2px solid $white;
            color: $white;
            display: inline-block;
            font-size: 18px;
            padding: 5px 15px;
            text-transform: uppercase;
            transition: all 0.5s ease-in-out;

            &:hover {
              background: rgba(211, 169, 7, 1);
            }
          }
        }
      }
    }

    .fieldable-panels-pane {

      .field-basic-image-image {

        img {
          aspect-ratio: 1 / 1;
          object-fit: cover;
          margin: 0;
        }
      }

      .field-basic-image-caption {
        background: rgba(31, 56, 30, 0.8);
        bottom: 0;
        color: $white;
        font-weight: bold;
        padding: 15px 40px;
        position: absolute;
        width: 100%;

        p {

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .popup_close {

      p {
        margin: 0;
      }

      .close-btn {
        background: rgba(31, 56, 30, 0.8);
        border: 0;
        color: $white;
        padding: 5px 15px;
        position: fixed;
        right: 0;
        text-transform: uppercase;
        top: 0;
      }
    }
  }
}

#mini-panel-email_sign_up_popup {

  > .panel-col {

    .pane-bundle-quick-links {

      ul {

        li {

          a {
            background: rgba(202, 109, 72, 0.8);
          }
        }
      }
    }

    .fieldable-panels-pane {

      .field-basic-image-caption {
        background: rgba(202, 109, 72, 0.8);
      }
    }

    .popup_close {

      .close-btn {
        background: rgba(202, 109, 72, 0.8);
      }
    }
  }
}
