.view-npt-landing-pages,
.view-panopoly-taxonomy,
.view-panopoly-taxonomy,
.view-featured-stories {

  > div {

    > div {
      border-bottom: 2px solid $gray;
      padding: 40px 0;

      &:first-child {
        border-top: 2px solid $gray;
      }

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}

.front {

  .view-npt-landing-pages,
  .view-panopoly-taxonomy,
  .view-panopoly-taxonomy,
  .view-featured-stories {

    > div {

      > div {
        border-bottom: 0;
        padding: 0;

        &:first-child {
          border-top: 0;
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.view-book-review {

  article {
    margin-bottom: 30px;
  }
}

article {

  &.node-teaser {

    h4 {
      font-size: 20px;
      line-height: 25px;
      margin: 0 0 30px;
    }

    .content {
      display: flex;
      font-size: 17px;
      gap: 35px;
      line-height: 25px;

      .content-left {
        flex-basis: 145px;

        img {
          width: 100%;
        }
      }

      .content-right {
        flex-basis: calc(100% - 180px);

        .field-abstract {
          font-family: RalewayMedium;
        }

        .hidden-links {
          padding: 0;

          .links {

            li {
              margin: 0;
            }
          }
        }

        .links {
          display: inline-block;
          margin: 0;

          li {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include md {

  .view-book-review {

    article {
      margin-bottom: 15px;
    }
  }
}
