.front {
	.podcast {
		background: $primary-color;
		display: inline-block;
		float: left;
		padding: 0;
		width: 100%;
		.podcast-left {
			float: left;
			// width: calc((800px) + ((100% - 1200px) / (2)));
			width: 100%;
			> div {
				padding: 0;
			}
			.featured_article_wrapper {
				.featured_article_body {
					.featured_article_inner {
						padding: 0;
					}
				}
			}
			.hidden-links {
				display: none;
			}
		}
		.podcast-right {
			float: left;
			padding: 35px 0;
			// width: calc((400px) + ((100% - 1200px) / (2)));
			width: 100%;
			> div {
				margin: 0 auto;
				max-width: 1200px;
				padding: 0 50px;
				width: 100%;
			}
			.pane-title {
				color: $white;
			}
			.view-podcasts {
				.views-field-nothing {
					display: table;
					.podcasts_image {
						display: table-cell;
						vertical-align: middle;
						width: 100px;
						.field-opengraph-banner {
							margin: 0;
						}
						img {
							width: 100%;
						}
					}
					.podcasts_body {
						display: table-cell;
						font-family: RalewayBold;
						line-height: 18px;
						padding: 0 0 0 15px;
						vertical-align: top;
						a {
							color: $white;
							font-size: 14px;
						}
					}
				}
				> div {
					display: grid;
					grid-column-gap: 70px;
					grid-row-gap: 35px;
					grid-template-columns: 1fr 1fr 1fr;
				}
				.view-footer {
					display: block;
					margin-top: 30px;
					text-align: center;
					p {
						margin: 0;
					}
					a {
						letter-spacing: 5px;
						color: $gray;
						font-family: RalewayBold;
						text-transform: uppercase;
						transition: 0.5s;
						&:hover {
							color: $wasabi;
						}
					}
				}
			}
		}
	}
	@include xxl {
		.podcast {
			.podcast-right {
				.panels-flexible-region-inside {
					padding: 0 15px;
				}
			}
		}
	}
	@include lg {
		.podcast {
			.podcast-right {
				.view-podcasts {
					> div {
						grid-template-columns: 1fr 1fr;
					}
				}
			}
		}
	}
	@include md {
		.podcast {
			.podcast-right {
				.view-podcasts {
					> div {
						grid-template-columns: 1fr;
					}
				}
			}
		}
	}
}

.pane-node-field-park-name,
.pane-node-field-article-category {

	.field-park-name,
	.field-article-category {

		.links {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			margin-bottom: 30px;

			li {
				border: 1px solid $body-links;
				border-radius: 6px;
				margin: 0;
				padding: 10px 20px;
			}
		}
	}
}