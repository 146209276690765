.pagination {

  > li {

    > a,
    > span {
      background: no-repeat;
      border: 0;
      border-left: 2px solid $greenish-black;
      color: $greenish-black;
      padding: 0 10px;
      transition: 0.5s;

      &:hover,
      &:visited,
      &:focus {
        background: no-repeat;
        color: $black;
        outline: none;
      }
    }

    &.disabled {

      > a,
      > span {
        background: no-repeat;
        color: $greenish-black;

        &:hover {
          background: no-repeat;
          color: $black;
        }
      }
    }

    &.first,
    &.last {

      > a,
      > span {
        border-radius: 0;
        color: $greenish-black;

        &:hover {
          color: $black;
        }
      }
    }

    &.active {

      > a,
      > span {
        background: no-repeat;
        border-radius: 0;
        color: $black;

        &:hover {
          background: no-repeat;
          color: $black;
        }
      }
    }

    &:last-child {
      
      > a,
      > span {
        border-right: 2px solid $greenish-black;
      }
    }
  }
}