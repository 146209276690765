body {
  font-family: RalewayMedium;
}

hr {
  border-top: 1px solid $gray;
}

p {
  margin: 0 0 15px;
}

.nopadding {
  padding: 0;
}

.page-title,
.pane-title {
  font-family: RalewayBold;
}

.front {

  .panels-flexible-row {
    padding: 0;
  }
}

#messages:empty {
  display: none;
}

.block-workbench {
  padding: 15px;
}

// Front page CSS
.panels-flexible-npt_custom_front_page {

  .panels-flexible-row {

    .panels-flexible-row-inside {
      display: table;
      margin: 0 auto;
      width: 100%;
    }

    &.slideshow {
      background: $white;
    }

    &.footer {
      background: $white;
    }
  }
}

.pager {

  li {

    > a {
      border-radius: 0;
    }

    > span {
      border-radius: 0;
    }
  }
}

.photography_page_header {
  margin-bottom: 50px;
}

.photography_page_wrapper {
  margin-bottom: 30px;

  h3 {
    margin: 0 0 10px;
  }

  img {
    margin-bottom: 15px;
    width: 100%;
  }

  p {
    margin: 0;
  }
}

.table-striped {

  > tbody {

    > tr {

      &:nth-of-type(odd) {
        background-color: $bright-gray;
      }

      &:nth-of-type(even) {
        background-color: $white;
      }

      &:hover {
        background-color: $gainsboro;
      }
    }
  }
}

#modalContent {

  .panels-categories-box {

    a {
      color: $white !important;

      &.active {
        color: $black !important;
      }
    }
  }
}

#main-wrapper {
  overflow: hidden;
}

.pane-support-national-parks-traveler,
.pane-financial-disclosure {

  .panels-flexible-region {
    float: none;
    width: 100%;
  }
}

.pane-node-body {

  .field-body {
    font-family: 'RalewayMedium';
    margin-bottom: 30px;
  }
}

.pane-support-national-parks-traveler {
  background: #293720;

  .pane-title {
    font-size: 30px;
    line-height: 30px;
    color: #A7BA2B;
    font-family: 'RalewayBold';
    margin-bottom: 0;
    padding: 30px 30px 0 30px;
  }

  .pane-content {

    .panels-flexible-region {
      background: #293720;
      padding: 30px;
      width: 100%;

      .fieldable-panels-pane {

        .field-basic-text-text {
    
          * {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-family: 'RalewayBold';
            line-height: 24px;
            text-align: left !important;
            margin: 0;
          }
        }
      }
    }
  }

  .supporters-button {
    margin-top: 30px !important;
    margin-bottom: 15px !important;
    background: #8B9B25 !important;
    border: 1px solid #707070;
    padding: 12px 24px;
    border-radius: 8px;
    max-width: 70%;

    .dbox-donation-page-button {
      font-size: 18px !important;
      font-family: 'RalewayBold' !important;
      margin-top: 15px;

      img {
        margin-right: 10px !important;
      }
    }
  }
}

.pane-financial-disclosure {
  background: #f4f5ea;
  margin-bottom: 40px;

  .field-basic-text-text {
    background: #f4f5ea;
    padding: 30px;
    width: 100%;
  }

  p {

    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    margin: 0;
    padding-left: 20px;

    li {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.teaser_list_view {

  .view-content {

    .views-row {
      border-bottom: 2px solid $gray;
      padding: 40px 0;

      h3 {
        font-size: 20px;
        line-height: 25px;
        margin: 0 0 30px;
      }

      .content {
        display: flex;
        font-size: 17px;
        gap: 35px;
        line-height: 25px;

        .content-left {
          flex-basis: 145px;
        }

        .content-right {
          flex-basis: calc(100% - 180px);
        }
      }

      &:first-child {
        border-top: 2px solid $gray;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  font-size: 17px;
  gap: 5px;
  line-height: 25px;

  .pane-addtoany-addtoany-button {
    margin: 0 0 0 auto;
  }
}

.teaser_list_view {

  .view-content {

    .views-row {
      margin: 0;
    }
  }

  .view-footer {
    margin-top: 20px;

    .more-link {
      text-align: center;
    }
  }
}

@include lg {

  .supporters-button {
    max-width: 80% !important;
  }
}

@include md {

  .supporters-button {
    max-width: 100% !important;
  }
}
