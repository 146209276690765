.navbar-default {

  .navbar-collapse {
    padding-right: 0;
  }

  .navbar-nav {

    .open {

      > .dropdown-menu {

        > li {

          &.dropdown {

            > a {

              > .caret {
                position: absolute;
                right: 5px;
                top: 10px;
                transform: rotate(-90deg);
              }
            }

            &:hover {

              > a {
                color: $raw-sienna;
              }

              > .dropdown-menu {
                background: no-repeat;
                border-bottom: 5px solid $primary-color;
                border-top: 5px solid $primary-color;
                display: block;
                max-height: 250px;
                padding: 0;
                overflow-y: auto;
                overflow-x: hidden;
              }
            }
          }
        }

        .dropdown-menu {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          left: 100%;
          margin: 0;
          min-width: 250px;
          padding-left: 1px;
          top: 0;
          width: 250px;

          > li {
            border-bottom: 1px solid $gray;
            width: 250px;

            &:first-child {
              border-top: 0;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  @include xxl {

    .navbar-nav {

      .open {

        > .dropdown-menu {

          .dropdown-menu {
            width: auto;
          }
        }
      }
    }
  }

  @include md {

    .navbar-nav {

      .open {

        > .dropdown-menu {

          > li {

            &.dropdown {

              > a {

                > .caret {
                  position: inherit;
                  transform: rotate(0deg);
                }
              }

              > .dropdown-menu {
                display: block;
                left: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include md {

  .navbar-header.usermenu,
  .container > .navbar-header.mainmenu {
    float: none;
    width: 100%
  }

  .navbar-default .navbar-nav > li > a {
    border-radius: 3px;
    color: #1f381e;
    background-color: #fff;
    padding: 5px 15px;
    margin-bottom: 2px;
  }

  .navbar .nav > .dropdown > a {
    float: none;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    background-color: #fff;
    padding-left: 20px;
    border-radius: 3px;
    color: #1f381e;
    margin-bottom: 1px;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:before {
    content: "";
  }

  .navbar-toggle {
    font-size: 32px;
    color: black;
    background-color: white;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    top: 8px;
    float: none;
    line-height: 1;
    margin-bottom: 0.5em;

      &:hover {
        color: black;
        background-color: white;
      }
  }

 .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    color: black;
    background-color: white !important;
  }
}
