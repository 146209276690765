.front {
	.slideshow {
		display: table;
		width: 100%;
		.slideshow-wrapper {
			float: none;
			margin: 0 auto;
			max-width: 1200px;
			width: 100%;
			.view-slideshow {
				padding: 50px 0;
				.view-header {
					color: $greenish-black;
					font-size: 28px;
					font-weight: bold;
					padding: 0 0 20px;
					text-align: center;

					a {
						&:hover {
							color: $wasabi;
						}
					}
				}
				> div {
					.photo_caption {
						display: none;
					}
					&:nth-child(2) {
						.flexslider {
							margin: 0;
							padding: 0;
							.flexslider-views-slideshow-main-frame {
								margin: 0 auto;
								max-width: 800px;
								width: 100%;
							}
							.flex-control-paging {
								display: none;
							}
							.flex-direction-nav {
								li {
									a {
										background: url('../images/arrow.png');
										background-repeat: no-repeat;
										background-size: contain;
										display: block;
										height: 60px;
										opacity: 1;
										&.flex-prev {
											left: 10px;
											-ms-transform: rotate(180deg); /* IE 9 */
											transform: rotate(180deg);
										}
										&.flex-next {
											right: 10px;
										}
										&:before {
											font-size: 50px;
											visibility: hidden;
										}
									}
								}
							}
						}
						.views-slideshow-controls-bottom {
							display: none;
						}
					}
				}
				.view-footer {
					color: $greenish-black;
					font-size: 18px;
					letter-spacing: 3px;
					padding: 20px 0 0;
					text-align: center;
					text-transform: uppercase;
				}
			}
		}
	}
	@include md {
		.slideshow {
			padding: 0 15px;
			.slideshow-wrapper {
				.view-slideshow {
					padding: 30px 0;
				}
			}
		}
	}
}

#cboxTitle {
	> div {
		margin: 0 0 15px;
	}
	p {
		margin: 0 0 15px;
		&:last-child {
			margin: 0;
		}
	}
}