/**
Style Header layout
**/

.front {
  .navbar {
    .container {
      &.header-block {
        margin: 0 auto;
        max-width: 1200px;
        padding: 30px 50px 20px;
        width: 100%;
        @include lg {
          padding: 30px 15px 20px;
        }
      }
    }
  }
}
.container {
  > .navbar-header {
    > .navbar-brand {
      height: inherit;
      padding: 0;
    }
    &.logo {
      float: none;
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      float: left;
      clear: right;
      @include md {
        float: none;
        clear: none;
      }
    }
    &.button {
      float: none;
      clear: right;
      margin-left: auto;
      margin-right: auto;
      float: right;
    }
    &.usermenu {
      clear: right;
      float: right;
      margin-left: auto;
      margin-right: auto;
    }
    &.socialmedias {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      float: right;
      padding: 0px 15px;
      @include md {
        float: none;
        padding: 0;
      }
        img {
        width: 30px;
        margin-top: 5px;
      }
    }
    &.search {
      margin-top: 5px;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
      float: none;
      @include md {
        float: right;
      }
      form {
        margin-bottom: 0;
      }
      .form-control {
        width: 164px;
        height: 27px;
        float: left;
        @include md {
        }
      }
      .form-submit {
        height: 30px;
        float: left;
        @include md {
        }
      }
    }
    &.mainmenu {
      clear: both;
      min-width: 200px;
      margin-left: auto;
      margin-right: auto;
      float: left;
      .navbar-nav > .active > a, {
        li {
          background-color: transparent;
        }
      }
    }
    margin-right: 0;
    margin-left: 0;
  }
}

.dropdown-toggle {
  padding-right: 15px;// !important;
  padding-left: 0;// !important;
}

.navbar .nav > .dropdown > .dropdown-toggle {
  padding-left: 15px;
  padding-right: 17px;
  float: none;
}

.navbar-default {
  background-color: $primary-color;
  background-image: none;
  color: white;

  nav.menu {
    float: none;
  }
  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
    border-top: $menu-border1;
    border-bottom: $menu-border2;
    > .active > a, > .active > a:hover, > .active > a:focus, > li > a, > li > a:hover, > li > a:focus, > .open > a, > .open > a:hover, > .open > a:focus {
      background-color: $primary-color;
      background-image: none;
      color: white;
    }
    >li>a {
      color: #ffffff;
    }
    >div.visible-xs {
      border-right: $menu-border2;
      border-left: $menu-border1;
    }
    >li>a {
      border-right: 0;
      border-left: 0;
      border-top: 0;
    }
    .nav>li {
      padding-left: 15px;
      padding-right: 15px;
      border-right: $menu-border2;
      border-left: $menu-border1;
    }
    .menu.navbar-collapse {
      border-color: $primary-color;
    }
    .nav>li>a {
      padding: 10px 0;
    }
    > .dropdown {
      > .dropdown-menu {
        background: no-repeat;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        max-height: calc(100vh - 140px);
        min-width: 250px;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100vw;
        li {
          background: $white;
          border-bottom: 1px solid $gray;
          border-left: 1px solid $gray;
          border-right: 1px solid $gray;
          line-height: 20px;
          padding: 5px;
          width: 300px;

          &.dropdown.last {
            > .dropdown-menu {
              bottom: 0;
              top: auto;
            }
          }

          &:first-child {
            border-top: 5px solid $primary-color;
          }

          &:last-child {
            border-bottom: 5px solid $primary-color;
          }
        }
        a {
          white-space: break-spaces;
        }
      }
    }
  }
  .navbar-collapse {
    -webkit-box-shadow: inset 0 0 0 rgba(255,255,255,0.1);
    border: none;
  }
  @include xxl {
    .navbar-nav {
      > .dropdown {
        > .dropdown-menu {
          left: calc(50% - 150px);
        }
      }
    }
  }
}

.logged-in {
  .navbar-default {
    .navbar-nav {
      > .dropdown {
        > .dropdown-menu {
          max-height: calc(100vh - 225px)
        }
      }
    }
  }
}

.html {
  header {
    border-radius: 0;
    .navbar-header-top {
      &.logo {
        width: auto;
        .navbar-brand {
          margin: 0;
        }
        #logo {
          float: left;
          margin-right: 15px;
          img {
            width: 240px;
          }
        }
        #site-slogan {
          color: $greenish-black;
          float: left;
          font-family: 'RalewayMedium';
          font-size: 14px;
          font-style: italic;
          line-height: 17px;
          margin-top: 33px;
          text-shadow: none;
          width: 139px;
          p {
            margin: 0;
          }
        }
      }
      .navbar-collapse {
        #secondary-menu-links {
          li {
            a {
              background: $tertiary-color;
              color: $black;
              font-family: RalewayBold;
              font-size: 17px;
              line-height: 20px;
              padding: 6px 25px;
              text-shadow: none;
              &:after {
                  background: $black;
                  content: "";
                  height: calc(100% - 10px);
                  position: absolute;
                  right: -3px;
                  top: 5px;
                  width: 3px;
              }
            }
            &:first-child {
              a {
                color: $raw-sienna;
                margin-right: 3px;
                &:after {
                  background: $raw-sienna;
                }
              }
            }
            &:nth-child(2) {
              a {
                color: $hippie-blue;
                margin-right: 3px;
                &:after {
                  background: $hippie-blue;
                }
              }
            }
            &:nth-child(3) {
              a {
                color: $wasabi;
                &:after {
                  display: none;
                }
              }
            }
            &:nth-child(4) {
              a {
                background: $galliano;
                color: $white;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      &.mainmenu {
        clear: right;
        float: right;
        padding-top: 54px;
        .navbar-nav {
          li {
            a {
              background-color: $white;
              color: $greenish-black;
              font-family: RalewayMedium;
              font-size: 16px;
              text-shadow: none;
              padding: 0 15px;
              &:hover {
                color: $raw-sienna;
              }
              &.active {
                color: $raw-sienna;
              }
            }
          }
          > li {
            &:last-child {
              a {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    &.navbar {
      background: $tertiary-color;
      border: 0;
    }
  }
  .header-bottom-left {
    background: $hippie-blue;
    font-family: RalewayBold;
    width: 50%;
    > div {
      padding: 0 15px;
    }
    p {
      color: $white;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      padding: 2px 0;
      text-align: center;
      text-transform: uppercase;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  .header-bottom-right {
    background: $wasabi;
    font-family: RalewayBold;
    width: 50%;
    > div {
      padding: 0;
    }
    p {
      color: $white;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      padding: 2px 0;
      text-align: center;
      text-transform: uppercase;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  @include xl {
    header {
      .navbar-header-top {
        &.mainmenu {
          .navbar-nav {
            li {
              a {
                padding: 0 5px;
              }
            }
          }
        }
        .navbar-collapse {
          #secondary-menu-links {
            li {
              a {
                padding: 6px 15px;
              }
            }
          }
        }
      }
    }
  }
  @include lg {
    header {
      #site-slogan {
        display: none;
      }
      .navbar-header-top {
        &.usermenu {
          width: auto;
        }
        &.mainmenu {
          width: auto;
        }
      }

    }
  }
  @include md {
    header {
      overflow: hidden;
      .header-block {
        padding: 15px 15px 10px!important;
        position: relative;
        text-align: center;
        .navbar-header-top {
          .navbar-collapse {
            #secondary-menu-links {
              li {
                a {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
          &.logo {
            display: inline-block;
            float: none;
            #site-slogan {
              display: none;
            }
            #logo {
              margin: 0;
            }
          }
          &.navbar_toggle_wrapper {
            display: inline-block;
            float: right;
            .navbar-toggle {
              border: 0;
              color: $raw-sienna;
              margin: 45px 0 0;
              padding: 0;
            }
          }
          &.usermenu {
            padding: 0;
            text-align: left;
            width: 100%;
            > nav {
              padding: 0;
              ul {
                margin: 0;
                li {
                  a {
                    border-bottom: 3px solid;
                    border-radius: 0;
                    border-right: 0 !important;
                    margin-bottom: 15px;
                    padding: 0 0 15px !important;
                  }

                  &:last-child {
                    a {
                      border: 0;
                      padding: 6px 15px !important;
                    }
                  }
                }
              }
            }
          }
          &.mainmenu  {
            padding: 0;
            text-align: left;
            width: 100%;
            > nav {
              padding: 0;
              overflow-x: hidden;
              overflow-y: scroll;
              max-height: calc(100vh - 434px);
              ul {
                margin: 0;
                li {
                  border-bottom: 1px solid $greenish-black;
                  a {
                    margin: 0;
                    padding: 5px 0;
                    white-space: normal;
                  }
                  .dropdown-menu {
                    background: $white;
                    border: 0;
                    box-shadow: none;
                    border-radius: 0;
                    left: 0;
                    margin: 0 0 15px;
                    max-height: unset;
                    padding: 0 0 0 15px;
                    position: inherit;
                    width: 100%;
                    li {
                      border: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .header-bottom-left {
      width: 100%;
    }
    .header-bottom-right {
      width: 100%;
    }
  }
}

section.header_bottom {
  background: $primary-color;
  margin-bottom: 20px;
  .header_bottom_wrapper {
    display: table;
    margin: 0 auto;
    width: 100%;
    .header_bottom_left {
      background: $hippie-blue;
      float: left;
      width: 50%;
      .content {
        p {
          font-family: 'RalewayBold';
          margin: 0;
          padding: 2px 15px;
          text-align: center;
          a {
            font-size: 15px;
            line-height: 18px;
            text-decoration: none;
            text-transform: uppercase;
            transition: 0.5s;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
    .header_bottom_right {
      background: $wasabi;
      float: left;
      width: 50%;
      .content {
        p {
          font-family: 'RalewayBold';
          margin: 0;
          padding: 2px 15px;
          text-align: center;
          a {
            font-size: 15px;
            line-height: 18px;
            text-decoration: none;
            text-transform: uppercase;
            transition: 0.5s;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  @include md {
    .header_bottom_wrapper {
      .header_bottom_left {
        width: 100%;
      }
      .header_bottom_right {
        width: 100%;
      }
    }
  }
}
.front {
  section.header_bottom  {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 810px){

  .html {
    header {
      .navbar-header-top {
        &.logo {
          #logo {
            img {
              width: 200px;
            }
          }
        }
        &.mainmenu {
          padding-top: 35px;
        }
      }
    }
  }
}

