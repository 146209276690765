.node-park-subpage {
  padding: 0 0 30px;

  h4 {
    font-size: 20px;
    line-height: 25px;
    margin: 0 !important;

    &:before {
      background: $gray;
      content: '';
      display: flex;
      height: 2px;
      margin-bottom: 30px;
    }
  }

  .content {

    .field-body {

      p {
        font-size: 17px;
        line-height: 25px;

        &:first-child, &:last-child {
          margin: 0;
        }
      }
    }
  }

  .park_subpage {
    display: flex !important;
    flex-wrap: wrap;
    font-size: 17px;
    line-height: 25px;
    padding-bottom: 0 !important;

    .links {
      margin: 0;

      li {
        float: none !important;
        margin: 0;
        padding-right: 25px !important;
      }
    }
  }
}

.pane-node-field-photo-credit {
  color: #707070;
  float: none !important;
  margin-bottom: 20px;
  padding-left: 0 !important;
  width: unset !important;

  .field-photo-credit {
    font-size: 15px;
    line-height: 25px;

    p {
      margin: 0;

      strong {
        font-weight: 400;
      }
    }
  }
}

.pane-node-field-location {
  width: 270px !important;
  margin-left: 35px;
  padding-left: 0 !important;
}

.pane-node-field-existing-user {
  background: $white-grey;
  width: 270px !important;
  padding: 30px !important;
  margin-left: 35px;
  margin-bottom: 35px;

  .pane-title {
    font-size: 20px;
    line-height: 20px;
  }

  .pane-node-field-short-bio {
    padding-left: 0;
    width: unset;

    .field-short-bio {
      font-size: 15px;
      line-height: 20px;

      em {
        font-style: normal !important;
      }
    }
  }
}

.pane-node-field-park-name {

  .field-park-name {

    li {
      margin: 0;
    }
  }
}

@include lg {

  .pane-node-field-location {
    width: 180px !important;
    margin-left: 20px;
    padding-left: 0 !important;
  }

  .pane-node-field-existing-user {
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 15px !important;
    width: 180px !important;

    .pane-node-field-short-bio {
      width: 180px;
      padding: 15px;
      padding-left: 30px;

      .field-short-bio {

        a {
          word-break: break-word;
        }
      }
    }
  }
}