@font-face {
	font-family: 'RalewayBold';
	src: url('../fonts/Raleway/static/Raleway-Bold.ttf');
}

@font-face {
	font-family: 'RalewaySemibold';
	src: url('../fonts/Raleway/static/Raleway-SemiBold.ttf');
}
@font-face {
	font-family: 'RalewayMedium';
	src: url('../fonts/Raleway/static/Raleway-Medium.ttf');
}

// Project Imports:
@import "../components/all";

ul, ol, menu, dir {
  -webkit-padding-start: 25px;
}

.progress {
  background-color: #1f381e;
}

code {
  color: black;
  background-color: #DAE0DA;
}


body.draft,
body.draft #main {
  background-color: #f9dce2;
}

body.needs_review,
body.needs_review #main {
  background-color: #f7f9c5;
}

body.scheduled,
body.scheduled #main {
  background-color: #c3f9bb;
}



