div {

  &.caption,
  &.caption-width-container {
    width: 100%;
  }
}

div.caption {

  &.panopoly-image-half {
    float: none;
  }

  &.caption-right,
  &.caption-left {
    line-height: 1.3;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 50%;
    color: #9B9380;
    width: auto;

    > p {
      width: 400px;
    }

    > img {
      margin: 0 auto 10px;
    }

    .media-wrapper {
      display: inline-block;
    }
  }

  &.caption-left {
    float: left;
    margin-left: 0;
    margin-right: 45px;
  }

  &.caption-right {
    float: right;
    margin-left: 45px;
    margin-right: 0;
  }
}

// copied from panopoly_images.css and converted for our photo_caption field
// classes applied in field--field-photo-caption--article.tpl.php
/**
 * Image styling
 */
div.field-photo-caption {
  //font-size: .92em;
  color: #9B9380;
  line-height: 2em;
  &.panopoly-image-full {
    max-width: 100%;
    width: 100% !important;
    height: auto;
    margin-bottom: .5em;
  }
  &.panopoly-image-half {
    max-width: 50%;
    width: 50%;
    height: auto;
    float: left;
    margin-right: .75em;
    margin-bottom: .5em;
    margin-top: .5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  &.panopoly-image-quarter {
    max-width: 25%;
    width: 25%;
    height: auto;
    float: left;
    margin-right: .5em;
    margin-bottom: .25em;
    margin-top: .25em;
  }
  &.panopoly-image-featured {
    max-width: 100%;
    height: auto;
  }
  &.panopoly-image-thumbnail {
    max-width: 100%;
    height: auto;
  }
  &.panopoly-image-square {
    max-width: 100%;
    height: auto;
  }
}
