.pane-npt-landing-pages-latest-news {
	.node-advpoll {
		&.node-teaser {
			.content {
				form,
				.poll {
					display: none;
				}
			}
		}
	}
}

.panels-flexible-row-npt_custom_front_page-7 {
	&.donor-box {
		display: none;
	}
}
