//Hero Image

.remove-bg {
  background: none !important;

  .remove-right-sidebar {
    display: none;
  }

  #block-workbench-block {
    display: none;
  }

  .container-fluid {
    position: relative;
    width: 786px;

    .npt-front-mainbar {
      width: 100%;

      .field-body {
        font-family: 'RalewayRegular';
      }
    }
  }

  #donate-sidebar {
    display: none;
  }
}

.field-hero-image {
  position: relative;
  margin-left: calc(-50vw + 50%);
  max-width: inherit;
  width: 100vw;

  .image-wrapper {
    position: relative;

    .hero-image-with-style {

      .hero-image {
        width: 100%;
      }

      .unstyled-image {
        display: none;
      }
    }

    .enlarge-button {
      position: absolute;
      bottom: 0;
      left: 0;
      color: white;
      padding: 0 15px;
      border: 1px solid #843F1C;
      background: #843F1C;
      width: 250px;
      height: 28px;

      .close-icon {
        padding-left: 100px;
      }
    }
  }

  .enlarged {
    position: absolute !important;
    margin-left: calc(-50vw + 50%);
    max-width: inherit;
    width: 100vw;
    z-index: 1;

    .hero-image-with-style {
      align-items: center;
      background: rgba(0, 0, 0, 0.9);
      bottom: 0;
      display: flex;
      left: 0;
      max-height: 100vh;
      padding: 50px;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;

      .hero-image {
        display: none;
      }

      .unstyled-image {
        display: block;
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .title-author-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 650px;
    height: 80%;
    background: none;
    padding: 134px 50px 0 50px;
    z-index: 0;

    .pane-node-title {
      position: relative;
      z-index: 1;

      a {
        position: relative;
        z-index: 1;
      }

      h1 {
        color: #fff;
        font-size: 60px;
        line-height: 60px;
        opacity: 0.8;
        z-index: 999;
      }
    }

    .pane-title {
      color: #D3A907;
      font-weight: 600;
    }

    .field-author {
      color: #D3A907;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
      margin-bottom: 10px;

      a {
        color: #D3A907;
      }
    }

    .pane-node-published-at,
    .pane-published-date {
      color: #D3A907;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;

      .pane-title {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: -1;
      pointer-events: none;
    }
  }

  .remove-title-author {
    display: none !important;
  }

  &.hero-image-wrapper {
    z-index: 999999;

    .enlarged {
      position: relative;

      .enlarge-button {
        bottom: 0;
        position: fixed;
      }
    }
  }
}

@include lg {

  .field-hero-image {
    margin-left: 0;

    .title-author-wrapper {
      height: 80%;
      padding: 30px;
      width: 55%;

      .pane-node-title {

        h1 {
          font-size: 32px !important;
          line-height: 32px !important;
        }
      }

      .field-author {
        font-size: 24px !important;
        height: auto;
        line-height: 24px !important;
        margin-bottom: 0 !important;
        width: 100%;
      }
    }
  }

  .remove-bg {

    .container-fluid {
      width: 100%;
    }
  }
}

@include md {

  .field-hero-image {
    margin-left: 0;

    .title-author-wrapper {
      height: 65%;
      padding: 20px;
      width: 100%;

      .pane-node-title {

        h1 {
          font-size: 20px !important;
          line-height: 20px !important;
          margin-bottom: 15px !important;
        }
      }

      .field-author {
        font-size: 18px !important;
        line-height: 18px !important;
      }
    }
  }
  .unstyled-image {
    height: auto;
  }
}

