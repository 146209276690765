.floating_social_media_links {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  .sharethis-wrapper {
    background: #000;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 10px 15px 13px;

    span {
      line-height: 0;
      margin: 0;
    }
  }
}

@include md {

  .floating_social_media_links {
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    transform: none;

    .sharethis-wrapper {
      border-radius: 0;
      flex-direction: row;
      justify-content: center;
    }
  }
}
