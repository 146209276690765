$menu-border1: 0px solid rgba(255,255,255,0.1);
$menu-border2: 0px solid rgba(0,0,0,0.2);

// Padding
$padding-small: 10px;
$padding-med: 15px;
$padding-large: 30px;

// Colors:
$black: #000000;
$greenish-black: #595b60;
$white: #FFFFFF;
$wasabi: #8B9B25;
$hippie-blue: #579CAB;
$galliano: #D3A907;
$raw-sienna: #CA6D48;
$gray: #BDBDBD;
$bright-gray: #EBE9E7;
$gainsboro: #dddddd;

$primary-color: #1f381e ;  // top banner background
$secondary-color: #edeeef; // #edeeef light gray body background
$tertiary-color: $white;     // body, #container background-color: #fff pure white
$off-white: #fcf9f9;       // Or with memorable names...
$light-yellow: #fc0;       // Accent Yellow #FC0 bright yellow
$orange: #d05f24;          // Accent Orange #D05F24 orange
$body-links: #843F1C;      // Body links #044E8E
$body-link-hover: $wasabi;    // Body links #044E8E
$white-grey: #e7e7e7;      
//$body-links: red;      // Body links #044E8E
$inverted-text: #c8c8c8;
$inset-color: #ebe9da;     // Inset background #ebe9da
$panel-text-color: #c8c8c8;
$divider: 1px solid #d5d5d5;


// Fonts:
$base-font-size: 16px;
$body-font: Verdana, Tahoma, sans-serif;
$heading-font: Georgia, "Times New Roman", serif;

/**
Fonts
**/

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.3)); // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-base;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;

// Media Screen
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 320px;
//Small Screen Phone
$screen-smm-max: 450px;
// Small tablets (portrait view)
$screen-md-max: 767px;
// Tablets and small desktops
$screen-lg-max: 991px;
// Large tablets and desktops
$screen-xl-max: 1199px;
$screen-xxl-max: 1230px;

// Shadow
$shadow			:rgba(0,0,0, .6);
$shadow-default	:rgba(0,0,0, .9);
