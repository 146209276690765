////////////////////////
// Add your look-and-feel CSS here.
////////////////////////

.container {
  width: 100%;
}
main {
  color: black;
  a, a:visited {
    color: $body-links;
    text-decoration: none;
  }
  a:hover {
    color: $body-link-hover;
    text-decoration: none;
  }
  .panel, input, .nav-pills, .pagination {
    color: $inverted-text;
    a, a:active, a:visited {
      color: $inverted-text;
    }
  }
  .breadcrumb, div.breadcrumb {
    color: $body-links;
    text-shadow: none;
    background-image: none;
    border: none;
    padding: 4px 15px;
    a, a:active, a:visited {
      color: $body-links;
    }
  }
}
body, header.navbar {
  background-color: $primary-color;
  &.not-front {
    background: $white;
  }
}

// item-lists 
.item-list ul li {
    margin: 0 0 1em 1.5em;
    padding: 0;
}

ol li,
ul li {
    margin: 0 0 1.0em 1.0em;
    padding: 0;
}

nav ul li {
  margin: 0;
}

// Navbar fixes for Bootstrap
.navbar-nav>li>a {
    padding-top: 0em;
    padding-bottom: 1em;
    line-height: 1.1;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 1.1;
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,.navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none
    }
}

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding-top: 0em;
        padding-bottom: 1em
    }
}

// Adding space below messages box

#messages {
  margin-bottom: 15px;
}

// Front page stuff

.views-row, .row-npt-front, .npt-front-content {
//  border-bottom: $divider;
}
.view-latest-news {
  .views-field-created {
    font-style: italic;
  }
  .gridborder {
    //  border-top: $divider;
    &.col-item-1, &.col-item-2, &.col-item-3 {
      //    border-top: none;
    }
  }
}

.view-featured-stories {
  .views-field-created {
    font-style: italic;
  }
  .gridborder {
    // border-top: $divider;
    &.col-item-1, &.col-item-2 {
      // border-top: none;
    }
  }
}

.views-slideshow-cycle-main-frame-row-item {
  border-bottom: none;
}
.npt-front-mainbar {
  // border-right: $divider;
  @include lg {
  }
}
.views-field.views-field-field-basic-image-image img {
  border: 2px solid #ccc;
}
div#tabs, div.breadcrumb {
  margin-bottom: 0;
}
blockquote {
  border-left: 5px solid #1f381e;

}
// browse page filter box, positioned to the right of the content list
.pane-views-6f1406bd825cb28d94042ee328e4a556, .pane-views-8410cf76901a972f99ff7e2ae5a327a8 {
  float: right;
}
.pane-bundle-text > .pane-title {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}
.node-readmore {
  font-weight: bold;
}
a.title-edit-link {
  float: left;
  background: url('../images/edit-24.png') no-repeat;
  border:none;
  display: inline-block;
  width: 30px;
  height: 30px;
  color: transparent;
  margin: 8px 0 0 0;
  &:hover {
    color: transparent;
  }
}

// Add spacing around ad blocks in right mini-panel

.pane-views-panes.pane-npt-park-guide-park-guide,
.pane-block.pane-dfp-top-square,
.pane-block.pane-dfp-silo1,
.pane-block.pane-dfp-silo2,
.pane-block.pane-dfp-silo3,
.pane-block.pane-dfp-sponsor {
  margin-bottom: 20px;
}

// Add spacing between leaderboard Ads and header items.

.header-block {
  margin-top: 10px;
}

// Ads stuff

#adblock1,
#adblock2,
#adblock3 {
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}



// Front page features
// Add space between items
// Add bold to titles, and make font a bit larger

.features-row,
.latest-news-row {
  clear: both;
  margin-bottom: 20px;
}

.features-row .views-field-title,
.latest-news-row .views-field-title {
  font-weight: bold;
  font-size: 110%;
}

// Adding font boldness to all body fonts in the main column

main .npt-front-content a {
  font-weight: bold;
}

#node-edit #edit-title {
  // font-size: 14px;
}

.panel-pane.pane-block.pane-addtoany-addtoany-button {
  float: right;
  margin-bottom: 1em;
}

// Submitted By

.submitted,
.by-line {
  font-style: italic;
  margin-bottom: 0.5em;
  font-weight: 600;
}

// Tweaks for buttons

form .form-actions .btn {
  margin-bottom: 10px;
}

// Form Editing fixes

.vertical-tabs {
  clear: both;
}

.form-toggle {
  float: none;
}

// Restrict the size of the Essential Guide image

pane-npt-park-guide-park-guide img {
  width: initial !important;
}

// Browse by Category CSS
//
.views-widget-filter-field_park_name_tid,
.views-widget-filter-field_article_category_tid {
  width: 100%
}

// forum image style
.forum-image .linkit-thumb {
  margin-left: 9px;
}

// Styles for Subscription box
//
#subscriptions-ui-node-form a
{
  color: #843F1C;
}

#subscriptions-ui-node-form th,
#subscriptions-ui-node-form td

{
  color: black;
}

// Subscriptions on user pages

body.page-user #main .profile a,
body.page-user #main .profile a:visited
{
  color: #843F1C;
}

body.page-user #main .panel,
body.page-user #main .panel-default,
body.page-user #main th,
body.page-user #main td
{
  color: black;
}

body.page-user #main table .checkbox input[type=checkbox]
{
  margin-left: 0px;
}

// All Submit buttons, include bottom margin

#edit-submit {
  margin-bottom: 15px;
}

.joinus {
  text-align: center;
  font-size: 175%;
  color: white;
  background: #4C8729;
  margin-right: 25%;
  margin-left: 25%;
  padding: 17px;
  border-radius: 20px;
  font-weight: bold;
}

// Cart page styling
// 

body.page-cart,
body.page-checkout {
  main {
    .table {
      color: black;
      th {
        color: white;
      }
      a {
        color: #843F1C;
      }
      .views-field-edit-quantity {
        display: none;
      }
    }
  }
  #edit-submit {
    margin-bottom: 10px;
  }
  #edit-actions {
    display: none;
  }
}



.menu-block-ctools-user-menu-1 li {
  float: left;
  padding-right: 40px;
  
}

.menu-block-ctools-user-menu-1  {
  text-align: center;
}

body.page-user #main #tabs a, body.page-user #main #tabs a:visited
{
  color: white;
}

body.page-user #main th {
  color: white;
}

body.page-user #main th a {
  color: white;
}

// Table Styling 

table {
  thead {
    background-color: $primary-color;
    color: white;
    font-size: 125%;
    th {
      border-color: 1px solid black !important;
    }
  }

  tbody > tr:nth-of-type(odd) {
    background-color: #eef5ee;
  }

  tbody > tr:nth-of-type(even) {
    background-color: #dff0d8;
  }
}

table.small-only {
  .st-head-row {
    background-color: $primary-color !important;
    color: white;   
  }
  .st-key {
    text-align: right;
  }
}

// Effing Bootstrap crap!
.table>thead>tr>th {  border: 1px solid $primary-color !important;}


// How about some responsive tables 
table.small-only {
  display: none;
}
@include md {
  table.small-only {
    display: table;
  }
  table.large-only {
    display: none;
  }
}

// Let's do some styling for Panopoly table widgets!

.field-basic-table-table {
  table {
    width: 100%;
    margin-bottom: 1em;
    border-bottom: 1em solid $primary-color;
    th {
      padding: 10px;
    }
    td {
      padding: 5px;
    }
  }
}

.alert a {
  color: #843F1C;
}

#edit-pay-now {
  margin-bottom: 20px;
}


// Book Review product block
body.node-type-book-review {
  .book-review-panel-pane {
    @include md {
      max-width: 50%;
      float: left;
      margin-right: 1em;
    }
    border: 1px #ccc solid;
    padding: 6px;
    .book-image {
      margin-bottom: 15px;
      text-align: center;
    }
    .views-field-field-link-url-1 {
      border: 1px #ccc solid;
      background: #e8e7e7;
      margin: 5px;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
    }
  }
}

// Podcast Styles

body.node-type-podcast {
  .pane-entity-field.pane-node-field-podcast-widget,
  .pane-entity-field.pane-node-body,
  .pane-entity-field.pane-node-field-park-resources {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

// Podcast view
.pane-podcasts-panel-pane-2 {
  .views-row {
    margin-bottom: 1em;
  }
}

.node-teaser .field-opengraph-banner {
  float: left;
  margin-right: 1em;
  margin-bottom: 0.5em;
}


// Donations

#donate-sidebar {
  border: 1px #444242 solid;
  margin-bottom: 2.5em;
  border-radius: 5px;
  .pane-content {
    padding-top: 1em;
    margin-bottom: 1.5em;
    text-align: center;
  }
  .donate-logo {
    img {
      height: auto;
      margin: 0 auto;
      max-width: 400px;
      width: 85%;
    }
  }
}

main .donate {
  text-align: center;
  color: #000000;
  font-size: 100%;
  background: #4c8729;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  max-width: 85%;
  padding: 10px;
  line-height: 1;
  &:hover {
    color: #000000;
    background: #843F1C;
  }
  a,
  a:visited {
    color: #fff;
    padding: 10px;
    font-size: 175%;
  }
  a:hover {
    // color: #843F1C;
  }
  img {
    float: left;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
  }
}

main .donate:hover {
    color: #000000;
    background: #843F1C;
  }

main .donate::after { 
 content: " ";
 display: block; 
 height: 0; 
 clear: both;
}


// Footer Styling

.row-npt-footer-top {
  .item-list {
      background: #dff0d8;
      width: 100%;
    ul {
      text-align: center;
      width: 100%;
      margin-bottom: 1em;
      padding-top: .75em;
      li {
        display: inline-block;
        text-align: center;
        width: auto;
        margin-left: 1em;
        margin-right: 1em;
        a {
          font-weight: bold;
          font-size: 105%;
        }
      }
      @media (min-width: 768px) {
        li {
          margin-left: 2em;
          margin-right: 2em;
        }
      }
    }
  }
}

// Footer Bottom

.npt-footerbottom {
  margin: 10px 0;
  p {
    margin: 0;
  }
}


// Splashify stuff

.page-splashify-splash .navbar-header-top.navbar-header.socialmedias,
.page-splashify-splash #top-content  {
  display: none;
}

body.admin-menu.page-splashify-splash {
  margin-top: 0px !important
  #admin-menu {
    display: none;
  }
}

.page-splashify-splash {
  .region-content {
    text-align: center;
  }
  #main {
    min-height: 250px;
  }
}
