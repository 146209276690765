.hero-image {
  // margin-bottom: 50px;
  position: relative;

  .field-basic-image-image {
    margin-bottom: 0;
    margin-left: calc(-50vw + 50%);
    max-width: inherit;
    object-fit: cover;
    position: relative;
    width: 100vw !important;

    &:after {
      background: rgba(0, 0, 0, 0.2);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    img {

      &.panopoly-image-full {
        aspect-ratio: 1200 / 500;
        margin: 0;
        object-fit: cover;
      }
    }
  }

  .hero-content {
    color: $white;
    position: absolute;
    text-align: center;
    top: calc(50% - 18px);
    width: 100%;

    .title-edit-link {
      float: none;
    }

    h1 {
      display: inline-block;
    }
  }
}

.field-hero-image {
  margin-bottom: 25px;

  .pane-node-field-hero-caption {
    
    .pane-title {
      display: none;
    }

    .pane-content {
      background: #F3F3F3;
      border: 1px solid #ccc;
      text-align: center;
      padding: 5px 30px;
    }
  }
}
