.media,
.media--ratio {
  height: auto !important;
  padding: 0 !important;

  .media__element {
    height: auto;
    position: inherit;
    width: auto;
  }
}