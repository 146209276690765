.view-recent-comments ul.links.inline {
  clear: right;
  li {
    &.comment-delete,
    &.comment-edit,
    &.comment-reply,
    &.comment_forbidden {
      float: right;
      padding-left: 20px;
      font-size: 90%;
    }
  }
}
.comment {
  padding-left: 20px;
  border-left: 2px solid #4f4f4f;
  margin-bottom: 40px;
  p {
    word-break: break-word;
  }
}

.captcha {
  .panel-heading {
    background-color: #ffffff;

    .panel-title {
      text-align: center;
      color: #000;
    }
  }
  .panel-body {
    background-color: #ffffff;

    p {
      display: none;
    }

    img {
      border: 1px solid black;
    }

    .form-group {
      margin-bottom: 0;

      label {
        display: none;
      }

      .form-control {
        margin-top: 15px;
      }

      .help-block {
        color: #000;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
}

.pane-node-links {

  .pane-content {

    .comment-add {
      display: none;
    }
  }
}

.pane-recent-comments-panel-pane-1 {
  background: #f9f6f4;
  margin-bottom: -1px;
  padding: 35px;
  position: relative;

  .pane-title {
    color: #843F1C;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .view-recent-comments {

    .view-content {
      padding: 0;

      .comment {
        border-left: none;
        margin: 0;
        padding-left: 0;

        .media-body {

          .submitted {
            font-style: normal;
            margin: 0;

            a {
              font-size: 17px;
              line-height: 25px;
            }
          }

          .field-comment-body {

            p {
              margin: 0;
            }
          }
        }

        br {
          display: none;
        }
      }
    }

  }
}

.pane-node-comment-form {
  border: 1px solid $body-links;
  margin-bottom: 30px;
  padding: 40px;

  .pane-title {
    color: $body-links;
    font-size: 30px;
    font-family: 'RalewayBold';
    line-height: 25px;
    margin: 0;
    margin-bottom: 30px;
  }

  .comment-form {

    .form-item {

      label {
        color: $black;
        font-size: 17px;
        font-family: 'RalewayBold';
        line-height: 25px;
      }
    }

    .help-block {
      color: $black;
    }

    #edit-subscriptions-notify--3 {
      display: none;
    }

    .wysiwyg-toggle-wrapper {
      padding: 10px 0;
    }

    .captcha {

      .panel-title {
        font-size: 14px;
        line-height: 25px;
      }

      .form-item {

        input {
          width: 50%;
        }

        .help-block {
          font-size: 17px;
          line-height: 25px;
        }
      }
    }

    .form-actions {
      align-items: center;
      display: flex;
      gap: 30px;
      margin-bottom: 0;

      .form-submit {
        background: $black;
        border-radius: 6px;
        color: $white;
        font-size: 17px;
        line-height: 25px;
        margin: 0;
        padding: 8px 55px;
        text-transform: uppercase;
      }

      #edit-submit {
        margin-bottom: 0;
      }
    }

    .your_name-textfield {
      display: none;
    }
  }
}

@include md {

  .pane-node-comment-form {
    padding: 20px;

    .comment-form {

      .format-toggle {
        float: none;
      }

      .form-actions {
        align-items: normal;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
