.page-taxonomy {

  main {

    .npt-front-mainbar {

      h1 {
        font-size: 36px;
        line-height: 28px;
        margin-bottom: 40px;
      }

      .view-content {
        padding: 0;
      }
    }
  }
}
