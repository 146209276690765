#mini-panel-sidebar1 {

  .panel-panel-inner {

    .panel-pane {
      margin-bottom: 40px !important;
      margin-top: 0 !important;

      iframe {
        width: 300px !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .pane-title {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0 0 20px;
  }

  #donate-sidebar {
    border-radius: 0;
    padding: 35px;

    .pane-title {
      display: none;
    }

    .pane-content {
      padding-top: 0;
      margin-bottom: 0;

      .donate-logo {

        img {
          max-width: 100%;
          width: 100%;
        }
      }

      .donate-help-text {
        margin-bottom: 18px;
        margin-top: 10px;
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .right-sidebar-under-logo {
      margin: 0;
    }

    .donate {
      background: $wasabi;
      max-width: 205px;
      padding: 12px 16px;

      a {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .donate-nonprofit {
      max-width: 220px;
      margin: 0 auto;
    }
  }

  .survey_block {
    background: $galliano;
    font-family: 'RalewayMedium';
    font-size: 18px;
    line-height: 20px;
    padding: 37px;
    text-align: left;

    h3 {
      color: $white;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    p {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: $white;
    }
  }

  .rv_guild {
    border: 1px solid $gray;
    font-family: 'RalewayMedium';
    font-size: 18px;
    line-height: 20px;
    padding: 37px;
    text-align: left;

    .rving-guide-image {
      margin-bottom: 25px;
    }
  }
}
