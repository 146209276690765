////////////////////////
// Keeping structural CSS separate from stylistic CSS can be helpful. This file
// is a good place for page scaffolding elements and properties like width, height,
// margin, and padding.
////////////////////////

.hide {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  > div > div.column.container {
    padding: 0px;
  }
}

.not-front {

  header {

    .header-block {
      margin: 0 auto;
      max-width: 1230px;
      padding: 30px 15px;
    }
  }

  main {
    max-width: 1230px;

    #top-content {
      margin-bottom: 30px;
    }

    .row-npt-front-main {

      > div {

        &:first-child {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .pane-node-title {

        h1 {
          font-size: 60px;
          line-height: 60px;
          margin-bottom: 25px;

          a {
            color: $black;
          }
        }
      }

      .by-line {
        font-size: 24px;
        font-style: normal;
        line-height: 25px;
        margin-bottom: 30px;
      }

      .field-author {
        font-size: 24px;
        font-style: normal;
        line-height: 25px;
        margin-bottom: 10px;
      }

      .pane-node-field-author {
        display: flex;
        gap: 5px;

        .pane-title {
          font-family: 'RalewayMedium';
          font-size: 24px;
          font-style: normal;
          line-height: 25px;
          margin: 0;
        }
      }

      .pane-node-published-at,
      .pane-published-date {
        font-size: 24px;
        font-style: normal;
        line-height: 25px;
        margin-bottom: 30px;

        .pane-title {
          display: none;
        }
      }

      > .npt-front-mainbar {
        width: 815px;

        &.centered {
          float: none;
          margin: 0 auto;
        }
      }

      > .npt-front-sidebar {
        padding-left: 30px;
        width: 415px;

        .container-fluid {
          padding: 0;
        }
      }
    }
  }

  @include xxl {

    main {

      .row-npt-front-main {

        .pane-node-title {

          h1 {
            font-size: 32px;
            line-height: 1.1;
          }
        }

        > .npt-front-mainbar {
          float: left;
          width: calc(100% - 345px);
        }

        > .npt-front-sidebar {
          float: left;
          width: 345px;
        }
      }
    }
  }

  @include md {

    main {

      .row-npt-front-main {

        > .npt-front-mainbar,
        > .npt-front-sidebar {
          padding: 0 15px;
          width: 100%;
        }
      }
    }
  }
}

header.navbar {
  margin-bottom: 0;
}

#main-wrapper {
  margin-top: 0;
}

//.row {
.view-potw-list .gridcol.gridborder .grid-item, {
  padding: $padding-small 0;
}

.view-potw-list .views-row {
  padding-top: 0;
}

.npt-front-contentcolumn2, .npt-front-footercolumn2, .npt-front-footercolumn3 {
  //  padding: 0 $padding-med;
}

.row-npt-front-footer-bottom {
  padding-top: $padding-med;
}

.view.view-featured-stories.view-id-featured_stories.view-display-id-panel_pane_2 .views-row{
  min-height: 160px;
}

div#messages {
  width: 98% !important;
}

.pane-paypal-roles-traveler-premium-membership-1-ye {
  text-align: center;
}

#main-wrapper {
  margin-bottom: 0;
}

.devel {
  position: relative;
  left: -45px;
  height: 0;
  display: none;
  padding: 5px 20px 0 0;
  color: $wasabi !important;

  &.view {
    top: 20px;
  }
}

.pane-view-panes > .devel {
  top: 20px;
}

.byline {

  p {
    margin: 0;
  }
}

.edit-link {

  p {
    margin: 0;
  }
}

article {
  clear: left;
}

.alert {
  margin-bottom: 0;
}

.npt-front-contentcolumn1 {
  padding-top: $padding-med;
}

.video-filter,
.video-filter > iframe {
  aspect-ratio: 16/9;
  height: auto;
  width: 100% !important;
}

iframe {
  width: 100% !important;
  aspect-ratio: 16/9;
}


div {

  .pane-node-field-short-bio,
  .pane-node-field-existing-user,
  .pane-node-field-location,
  .pane-node-field-photo-credit {
    float: right;
    width: 40%;
    clear: right;
    padding-left: 10px;
  }
}

.clearfix:after {
  clear: none;
}

//.view-browse-archive, .view-featured-stories, .view-latest-news {
div.hidden-links {
  display: none;

  &.park_subpage,
  &.article,
  &.book_review,
  &.photo_of_week,
  &.puzzle,
  &.advpoll {
    display: block;
    margin-top: 15px;
    padding-bottom: 40px;

    .links.inline li {
      float: left;
      padding-right: 10px;
    }
  }
}

.node-teaser {

  .field-amazon-product-id, 
  .panopoly-image-thumbnail,
  .medium,
  .panopoly-image-square {
    margin-right: 10px;
    float: left;
    background: $orange;
    text-align: center;
    padding: 3px;
  }
}

.field-photographer-name {
  float: left;
  padding-right: 10px;
}

div.view-header {
  padding-top: 15px;
  padding-bottom: 15px;
}


/**
 * Views Pager CSS
 * Which updates the default bootstrap CSS
 */
.pagination>li>a,
.pagination>li>span {
  text-shadow: none;
  background-image: -webkit-linear-gradient(#484e55, #1f381e 80%, #313539);
  background-image: -o-linear-gradient(#484e55, #1f381e 80%, #313539);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(80%, #1f381e), to(#313539));
  background-image: linear-gradient(#484e55, #1f381e 80%, #313539);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1f381e', endColorstr='#ff313539', GradientType=0);
  -webkit-filter: none;
  filter: none;
}

.pagination>li.active>a,
.pagination>li.active>span {
  background-image: -webkit-linear-gradient(#1f381e, #101112 50%, #1f381e);
  background-image: -o-linear-gradient(#1f381e, #101112 50%, #1f381e);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1f381e), color-stop(50%, #101112), to(#1f381e));
  background-image: linear-gradient(#1f381e, #101112 50%, #1f381e);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1f381e', endColorstr='#ff191b1d', GradientType=0);
  -webkit-filter: none;
  filter: none;
}

.pagination>li>a:hover,
.pagination>li>span:hover {
  background-image: -webkit-linear-gradient(#020202, #101112 40%, #191b1d);
  background-image: -o-linear-gradient(#020202, #101112 40%, #191b1d);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#020202), color-stop(40%, #101112), to(#191b1d));
  background-image: linear-gradient(#1f381e, #101112 40%, #1f381e);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1f381e', endColorstr='#ff191b1d', GradientType=0);
  -webkit-filter: none;
  filter: none;
}

.pagination>li.disabled>a, .pagination>li.disabled>a:hover, .pagination>li.disabled>span, .pagination>li.disabled>span:hover {
  background-color: transparent;
  background-image: -webkit-linear-gradient(#484e55, #1f381e 80%, #313539);
  background-image: -o-linear-gradient(#484e55, #1f381e 80%, #313539);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(80%, #1f381e), to(#313539));
  background-image: linear-gradient(#484e55, #1f381e 80%, #313539);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1f381e', endColorstr='#ff313539', GradientType=0);
  -webkit-filter: none;
  filter: none;
}

/**
 * Default Button Styling
 *
 */
.btn-default {
  background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
  background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
  background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
  -webkit-filter: none;
  filter: none;
  color: #CAC8C8;

}

.btn-primary {
  font-weight: bold;
}

// Here's styling for the Polls
article.node-advpoll {
  margin-bottom: 25px;
}

// Layout stuff for mobile ads
.pane-dfp-mobile320 {
  text-align: center;
}

.layout_top {
  background: $primary-color;
  margin-bottom: 20px;
  margin-top: -20px;
  padding: 30px 0;

  .container {
    width: 90%;
  }
}

.front {

  .layout_top {
    margin: 0;

    .container {
      max-width: 1200px;
      padding: 0 50px;
      width: 100%;
    }
  }
}

@include xl {

  .layout_top {

    .container {
      width: 95%;
    }
  }
}

@include lg {

  .layout_top {

    .container {
      width: 98%;
    }
  }

  .front {

    .layout_top {

      .container {
        padding: 0 15px;
      }
    }
  }

  #main {

    .npt-front-mainbar {
      width: 100%;
    }
  }
}

@include md {

  .layout_top {

    .container {
      width: 100%;
    }
  }
}
