.gs-title,
.gs-result .gs-snippet,
.gs-webResult div.gs-visibleUrl-long {
  margin-bottom: .5em;
  margin-left: 0.5em;
}

.gsc-table-result tr {
  margin-top: 0.5em;
}

.gsc-control-cse .gs-result .gs-title > a {
  margin-left: 0px;
}

.gsc-control-cse {
  padding-left: 0px !important; 
  padding-right: 0px !important; 
}

.gsc-webResult.gsc-result {
  padding-left: 0px !important; 
  margin-top: .5em !important;
  img {
    margin-right: 0.5em
  }
  tbody {
    border-top: none;
  }
  padding-top: 1em !important;
  border-top: 1px gray solid !important;
}

.gs-result img.gs-image, .gs-result img.gs-promotion-image {
  padding-right: 0.5em;
}

@include md {

.gs-web-image-box img.gs-image, .gs-promotion-image-box img.gs-promotion-image {
  max-width: 150px !important;
  max-height: 150px !important;
}

.gs-web-image-box, .gs-promotion-image-box {
  min-width: 160px;
}

.gsc-table-cell-thumbnail, .gs-promotion-image-cell {
  min-width: 160px;

}

}