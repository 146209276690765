.node-type-article {

  .field-body {

    .caption {
      margin-bottom: 25px;
    }

    .body-image-wrapper {
      position: relative;

      .enlarge-body-image-button {
        background: #843F1C;
        border: 0;
        bottom: 0;
        color: #fff;
        cursor: pointer;
        font-family: 'RalewayMedium';
        font-size: inherit;
        font-weight: inherit;
        left: 0;
        line-height: inherit;
        padding: 2px 15px;
        position: absolute;
      }
    }
  }

  .pane-node-field-park-name,
  .pane-node-field-article-category {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;

    .pane-title {
      font-family: 'RalewayMedium';
      font-size: inherit;
      line-height: 1.5;
      margin: 3px 0 0;
      white-space: nowrap;
    }
  }

  .pane-node-field-article-category {
    margin-bottom: 30px;
  }
  
  .field-park-name,
  .field-article-category {

    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      margin-bottom: 0;

      li {
        border: 0;
        border-radius: 0;
        margin: 0 15px 0 0;
        padding: 0;

        &:after {
          color: $black;
          content: ',';
          font-size: 20px;
        }

        a {
          color: $body-links;
          font-weight: normal;
        }

        &:last-child {
          margin: 0;

          &:after {
            color: transparent;
          }

          a {
            border: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

/* The Modal (background) */
#imageModal {
  background-color: rgba(0,0,0,0.9);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  .modal-content {
    border-radius: 0;
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .close {
    align-items: center;
    background: #843F1C;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    justify-content: space-between;
    left: 0;
    opacity: 1;
    padding: 5px 30px;
    position: fixed;
    width: 250px;
  }
}

.newsletter-sign-up {
  align-items: center;
  border: 1px solid $raw-sienna;
  border-radius: 6px;
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  padding: 15px 30px;
  text-align: center;

  &:before {
    background: url('../images/email.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 73px;
    width: 100px;
  }

  h3 {
    color: $raw-sienna;
    font-size: 22px;
    margin: 0 0 5px;
  }

  p {
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }
  }

  a {
    background-color: $raw-sienna;
    border: 1px solid $raw-sienna;
    border-radius: 10px;
    color: white !important;
    display: inline-block;
    font-size: 16px;
    padding: 0 30px;
    text-shadow: none;
  }
}

#donate-popup-block {
  background: $galliano;
  bottom: 0;
  display: none;
  left: 0;
  padding: 60px 0;
  position: fixed;
  width: 100%;
  z-index: 999999;

  .pane-title {
    display: none;
  }

  .pane-content {
    margin: 0 auto;
    max-width: 630px;
    padding: 0 15px;
    width: 100%;

    .field-basic-text-text {

      .donate-popup-close {
        background: $raw-sienna;
        border: 0;
        color: $white;
        font-size: 18px;
        line-height: 0;
        margin: 0;
        padding: 15px 20px;
        position: absolute;
        right: 0;
        top: 0;
      }

      h3 {
        color: $white;
        margin: 0 0 5px;
      }

      p {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        border: 1px solid $black;
        color: $black;
        display: block;
        font-size: 14px;
        font-weight: normal;
        margin:0 auto;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        transition: 0.5s;
        width: 200px;

        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
  }
}

.pane-related-article {
  margin-bottom: 10px;

  .pane-title {
    margin: 0 0 15px;
  }
}

.view-related-article {

  .view-content {
    display: flex;
    gap: 15px;

    .views-row {
      position: relative;
      width: calc(33.333333% - 10px);

      .views-field-title {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        padding: 15px 30px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;

        a {
          color: $white;
        }
      }
    }
  }
}

@include md {

  .node-type-article {

    main {

      .row-npt-front-main {

        > .npt-front-mainbar {

          .pane-node-field-park-name {

            ul {
              grid-template-columns: repeat(1, 1fr);
            }
          }

          .pane-node-field-article-category {

            ul {
              grid-template-columns: repeat(1, 1fr);
            }
          }
        }
      }
    }
  }
}
