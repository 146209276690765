.view-sponsors {
	> div {
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		text-align: center;
		align-items: center;
	}
	.views-row {
		margin: 10px;
		img {
			filter: grayscale(100%);
		}
	}
}