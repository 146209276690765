////////////////////////
// Place code to generate your sprites here. The images for your sprites should
// go in images-source/sprites. See the README.txt there for info on how to store
// your sprite images.
//
// For each sprite, you'll need an @import and @include statement. For example:
//
// @import "sprites/social/*.png";
// @include all-social-sprites;
//
// The above will generate classes like ".social-facebook" and ".social-twitter"
// assuming you have provided facebook.png and twitter.png in the social directory.
//
// More info: http://compass-style.org/help/tutorials/spriting/
////////////////////////
