.front {
	.recent-news {
		display: inline-block;
		float: left;
		padding: 0;
		width: 100%;
		.recent-news-left,
		.recent-news-right {
			float: left;
			padding: 35px 0;
			// width: calc((400px) + ((100% - 1200px) / (2)));
			width: 100%;
			> div {
				margin: 0 auto;
				max-width: 1200px;
				padding: 0 50px;
				width: 100%;
			}
			.pane-title {
				color: $white;
				margin: 0 0 15px 0;
			}
			.featured_list_wrapper {
				.featured_list_body {
					a {
						color: $white;
						transition: 0.5s;
						&:hover {
							color: $wasabi;
						}
					}
				}
			}
			.view-featured-stories,
			.view-latest-news {
				> div {
					display: grid;
					grid-column-gap: 70px;
					grid-row-gap: 35px;
					grid-template-columns: 1fr 1fr 1fr;
				}
				.view-footer {
					display: block ;
					margin-top: 30px;
					text-align: center;
					p {
						margin: 0;
					}
					a {
						letter-spacing: 5px;
						color: $gray;
						font-family: RalewayBold;
						text-transform: uppercase;
						transition: 0.5s;
						&:hover {
							color: $wasabi;
						}
					}
				}
			}
			.view-featured-stories {
				.view-footer {
					a {
						color: $white;
						&:hover {
							color: $white;
						}
					}
				}
			}
		}
		.recent-news-left {
			// background: $wasabi;
			padding: 65px 0;
			position: relative;
			&:before {
				background-image: url('../images/DEVA-Dunefield_Kjr.webp');
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				content: ' ';
				display: block;
				height: 100%;
				left: 0;
				opacity: 0.8;
				position: absolute;
				top: 0;
				width: 100%;
			}
			> div {
				position: relative;
			}
			.view-featured-stories {
				> div {
					grid-column-gap: 35px;
				}
			}
			.featured_list_wrapper {
				align-items: center;
				background: rgba(255, 255, 255, 0.7);
				position: relative;
				.featured_list_body {
					background: rgba(0, 0, 0, 0.7);
					display: flex;
					flex-direction: column;
					height: 100%;
					justify-content: center;
					padding: 15px;
					position: absolute;
					width: 50%;
					z-index: 1;
					a {
						color: $white;
					}
				}
				.featured_list_image {
					width: 100%;

					img {
						aspect-ratio: 2 / 1;
					}
				}
			}
		}
	}
	@include xxl {
		.recent-news {
			.recent-news-left,
			.recent-news-right {
				> div {
					padding: 0 15px;
				}
			}
		}
	}
	@include xl {
		.recent-news {
			.recent-news-left {
				.view-featured-stories {
					> div {
						grid-template-columns: 1fr 1fr;
					}
				}
			}
		}
	}
	@include lg {
		.recent-news {
			.recent-news-right {
				.view-featured-stories,
				.view-latest-news {
					> div {
						grid-template-columns: 1fr 1fr;
					}
				}
			}
		}
	}
	@include md {
		.recent-news {
			> div {
				display: flex !important;
				flex-direction: column;
				.recent-news-left {
					.view-featured-stories {
						> div {
							grid-template-columns: 1fr;
						}
					}
				}
				.recent-news-right {
					.view-featured-stories,
					.view-latest-news {
						> div {
							grid-template-columns: 1fr;
						}
					}
					.featured_article_wrapper {
						height: 350px !important;
						.featured_article_body {
							padding: 0;
							.featured_article_inner {
								padding: 15px;
							}
						}
					}
				}
			}
		}
	}
}