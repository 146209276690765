#page_footer {
  background: $white;
  padding: 50px 0;
  
  .container {
    max-width: 1230px;

    &::before {
      background: $gray;
      height: 1px;
      margin-bottom: 50px;
      width: 100%;
    }
  }
  
  .page_footer_left {
    width: calc(100% - 345px);

    h2 {
      color: $greenish-black;
      margin: 0;
    }

    p {
      color: $greenish-black;
      margin: 30px 0 0;
    }
  }

  .page_footer_right {
    border-left: 2px solid $gray;
    padding-left: 65px;
    text-align: center;
    width: 345px;

    .socialmedias {
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      a {
        display: inline-block;
        width: 40px;
      }

      br {
        display: none;
      }
    }

    p {
      color: $greenish-black;
      display: inline-block;
      font-family: RalewayMedium;
      font-size: 13px;
      margin: 0;
      width: 100%;

      &.footer_policy_terms {
        font-family: RalewayBold;

        br {
          display: none;
        }

        a {
          color: $greenish-black;
          transition: 0.5s;

          &:hover {
            text-decoration: none;
            color: $wasabi;
          }

          &:first-child {
            border-right: 1px solid $greenish-black;
            padding-right: 8px;
          }

          &:last-child {
            padding-left: 5px;
          }
        }
      }

      &.footer_button {
        font-family: RalewaySemibold;
        font-size: 16px;

        a {
          border: 1px solid $greenish-black;
          color: $greenish-black;
          display: inline-block;
          margin-top: 30px;
          padding: 10px 30px;
          text-transform: uppercase;
          transition: 0.5s;

          &:hover {
            border-color: $wasabi;
            color: $wasabi;
            text-decoration: none;
          }
        }
      }
    }
  }

  @include lg {

    .page_footer_left {

      .view-sponsors {

        .views-row {
          margin: 0;
          padding: 10px;
          width: 16.666667%;
        }
      }
    }
  }

  @include md {

    .page_footer_left {
      padding-bottom: 15px;
      text-align: center;
      width: 100%;

      .block-views {
        border-bottom: 2px solid;
        padding-bottom: 30px;
      }

      h2 {
        font-size: 18px;
        text-align: left;
      }

      .view-sponsors {

        .views-row {
          margin: 0;
          padding: 10px;
          width: 33.333333%;
        }
      }
    }

    .page_footer_right {
      border: 0;
      padding: 0 15px;
      width: 100%;
    }
  }
}
