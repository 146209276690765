body.donate-page {

  #block-workbench-block,
  #top-content,
  .by-line {
    display: none;
  }

  .layout_top {
    margin-bottom: 0;
  }
}

#donate-25,
#donate-50,
#donate-100,
#donate-plus {
  max-width: 500px;
  margin: 0 auto;
  @include md {
    float: left;
    width: 50%;
    .pane-content {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

#recurring-donation {
  border: 1px gray solid;
  margin: 1em;
  margin-bottom: 2em;
  border-radius: 5px;
  padding: 0.5em;

  input[type="image"]  {
    max-width: 100%;
  }
}

.page-front-page {
  .donor-box {
    background: $primary-color;
    color: $white;
    display: inline-block;
    padding: 100px 15px;
    text-align: center;
    width: 100%;
    > div {
      display: table;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;
      > div {
        display: table-cell;
        float: inherit;
        vertical-align: middle;
        &.donor-box-left {
          width: 66.666666%;
          > div {
            padding: 0;
          }
          h3 {
            display: none;
          }
        }
        &.donor-box-right {
          width: 33.333333%;
          > div {
            padding-left: 50px;
          }
          p {
            a {
              border: 1px solid $white;
              display: inline-block;
              color: $white;
              padding: 10px 40px;
              transition: 0.5s;
              &:hover {
                color: $wasabi;
                border-color: $wasabi;
              }
            }
          }
        }
      }
    }
  }
  @include md {
    .donor-box {
      padding: 30px 15px;
      > div {
        > div {
          display: inline-block;
          width: 100%;
          &.donor-box-left {
            padding: 0 15px;
            > div {
              padding: 0;
            }
            display: block;
            width: 100%;
            h3 {
              display: block;
              font-size: 18px;
              margin-bottom: 15px;
              margin-top: 0;
            }
          }
          &.donor-box-right {
            > div {
              padding: 0;
            }
            display: block;
            width: 100%;
            .pane-content {
              padding: 0 20px;
              h3 {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.layout_top {

  .donate-wrapper {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: center;

    .donate-text {

      p {
        color: $white;
        font-size: 20px;
        line-height: 22px;
        margin: 0;
      }

      span {
        color: $raw-sienna;
        display: block;
        font-size: 14px;
      }
    }

    .donate-btns {
      align-items: center;
      display: flex;
      gap: 15px;

      a {
        border: 1px solid $white;
        padding: 5px 10px;
        text-decoration: none;
        transition: 0.5s;

        &:hover {
          background: $raw-sienna;
        }
      }

      br {
        display: none;
      }
    }
  }
}

#donate-modal {
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 50px 15px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999;

  .close {
    background: $raw-sienna;
    color: $white;
    opacity: 1;
    padding: 5px 15px;
  }

  > .block {
    display: inline-block;
  }
}

@include md {

  .layout_top {

    .donate-wrapper {
      align-items: center;
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  }

  #donate-modal {
    display: block;
    overflow: scroll;

    .close {
      float: none;
    }

    > .block {
      clear: both;
      display: block;
      margin: 0 auto;
      max-width: 425px;
    }
  }
}
