#search_icon {
	background: $raw-sienna;
	color: $white;
	cursor: pointer;
	display: none;
	left: 15px;
	padding: 2px 4px 0px 5px;
	position: absolute;
	top: 72px;
}
#search {
	bottom: -30px;
	display: none;
	left: 0;
	position: absolute;
	width: 100%;
	.search_wrapper {
		h2 {
			display: none;
		}
		.form-item {
			background-image: url(https://www.google.com/cse/intl/undefined/images/google_custom_search_watermark.gif);
			background-repeat: no-repeat;
			background-position: center left;
			float: left;
			margin: 0;
			width: calc(100% - 100px);
			label {
				display: none;
			}
			.form-control {
				background: rgba(211, 169, 7, 0.15) !important;
				border: 0;
				border-radius: 0;
				box-shadow: none;
				height: 30px;
			}
			&.search_focus {
				background: no-repeat;
			}
			&.search_has_text {
				background: no-repeat;
			}
		}
		.form-submit {
			background: $galliano;
			border: 0;
			border-radius: 0;
			color: $white;
			float: left;
			padding: 5px 5px 4px 5px;
			text-shadow: none;
			width: 100px;
		}
		#google-cse-results {
			display: none;
		}
	}
}
@include md {
	header {
		#search_icon {
			display: inline-block;
		}
		#search {
			display: block;
		}
		.header-block {
			transition: 0.5s;
			&.search_on {
				margin-bottom: 30px !important;
			}
		}
	}
}
.header_bottom {
	.header_bottom_right {
		position: relative;
		#search_icon {
			display: block;
			height: 100%;
			left: auto;
			right: 0;
			top: 0;
		}
		#search {
			background: $white;
			left: auto;
			right: 0;
			transition: 0.5s;
			width: calc((400px) + ((200% - 1200px) / (2)));
			&.show_search {
				display: block;
				width: 100%;
				z-index: 1;
			}
		}
	}
	@include lg {
		.header_bottom_right {
			#search {
				width: calc(200% - 120%);
			}
		}
	}
	@include md {
		.header_bottom_right {
			#search_icon {
				display: none;
			}
		}
	}
}
.not-front {
	.header_bottom {
		.header_bottom_right {
			#search {
				width: 100%;
			}
		}
	}
}

.page-search {

	.search-form {

		.form-submit {
			margin-bottom: 0 !important;
		}
	}
}

@include lg {

	.page-search {

		.container {
			padding: 0 15px !important;
		}
	}
}

@include md {

	.page-search {

		.search-form {

			.form-submit {
				margin-top: 10px !important;
			}
		}
	}
}