// Fonts:
html,
body,
blockquote {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
  line-height: 1.5;
  font-weight: 300;

    h1 {
      font-size: calc(32px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }
    h2{
      font-size: calc(27px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }
    h3{
      font-size: calc(22px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }
    h4{
      font-size: calc(17px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }
    h5{
      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }
    h6{
      font-size: calc(12px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: RalewayBold;
    }

  .more-link {
      font-size: calc(19px + (18 - 14) * ((100vw - 300px) / (2000 - 300)));
  }


}
/**
Fonts
**/

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.3)); // ~36px
$font-size-h2:            floor(($font-size-base * 2.0)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.6)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.5; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-base;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;




h1, h2, h3, h4, h5 {
  text-shadow: none;
}

h1, .h1 {
  // font-size: 28px;
  margin-top: 0;
}

h2, h3, h4, h5 {
  margin-top: 4px;
}

