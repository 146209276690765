#cboxOverlay {

  &:not(.cbox_overlay_slider) {
    background: $hippie-blue;
    border: none;
    bottom: 0;
    display: none !important;
    height: 300px;
    opacity: 0.8 !important;
    position: fixed;
    top: initial;
    width: 100%;
    z-index: 100;
  }
}

#colorbox {

  &:not(.colorbox_slider) {
    position: fixed !important;
    bottom: 0;
    height: auto !important;
    left: 0 !important;
    outline: none;
    top: initial !important;
    width: 100% !important;
    z-index: 99999;

    #cboxTitle {
      display: none !important;
    }

    .cboxIframe {
      aspect-ratio: auto;
      height: 300px;
    }

    #cboxWrapper {
      border-radius: 0;
      height: auto !important;
      position: initial;
      width: 100% !important;
    }

    #cboxTopLeft,
    #cboxTopCenter,
    #cboxTopRight,
    #cboxBottomLeft,
    #cboxBottomCenter,
    #cboxBottomRight {
      height: 0 !important;
    }

    #cboxMiddleLeft,
    #cboxMiddleRight {
      height: auto !important;
    }

    #cboxLoadedContent {
      background: $galliano;
      height: auto !important;
      margin: 0;
      padding: 60px 0;
      width: 100% !important;
    }

    #cboxContent {
      position: unset;
      height: auto !important;
      width: 100% !important;
    }

    #cboxClose {
      background: none;
      position: absolute;
      bottom: initial;
      color: $white;
      top: 0;
      right: 0;
      width: auto;
      padding: 15px 20px;
      line-height: 0px;
      text-indent: 0;
      font-size: 18px;
      background-color: $raw-sienna;
    }
  }
}

.splashify-modal-content-wrapper {
  align-items: end;
  display: flex;
  gap: 50px;
  margin: 0 auto;
  max-width: 780px;
  padding: 0 15px;
  text-align: left;
  width: 100%;

  .splashify-left {
    flex-basis: 500px;

    h3 {
      color: $white;
      margin: 0 0 15px;
    }

    p {
      color: $black;
      margin: 0;
    }
  }

  .splashify-right {
    flex-basis: 200px;

    a {
      color: $black;
      border: 1px solid $black;
      display: block;
      font-size: 14px;
      margin-bottom: 15px;
      padding: 5px 10px;
      text-align: center;
      text-decoration: none;
      transition: 0.5s;

      &:hover {
        background: $black;
        color: $white;
      }
    }

    br {
      display: none;
    }
  }
}

@include md {

  #cboxOverlay {

    &:not(.cbox_overlay_slider) {
      top: 0;
      bottom: initial;
      height: 100%;
    }
  }

  #colorbox {

    &:not(.colorbox_slider) {

      #cboxClose {
        font-size: 15px;
        padding: 15px;
      }
    }
  }

  .page-splashify-splash {

    .pop-up-wrapper {
      height: 100%;
      display: block;
      padding: 0;

      .pop-up-left {
        display: block;
        width: 100%;
      }

      .pop-up-right {
        display: block;
        padding: 15px;

        h2 {
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .splashify-modal-content-wrapper {
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .splashify-left {
      flex-basis: auto;
    }

    .splashify-right {
      flex-basis: auto;
      max-width: 200px;
    }
  }
}
